@import 'pages/variables.css';

html, body, #__next {
  height: 100%;
}

body {
  font: 16px/24px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: relative;
  width: 100%;
  z-index: 0;
  margin: 0;
  padding: 0;
  color: rgb($colorBlack);
  background: rgb($colorWhite);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

wbr {
  display: inline-block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
}

button,
fieldset,
form,
input,
label,
legend,
optgroup,
option,
output,
select,
textarea {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0 0 0.5em 0;
  padding: 0;
}

blockquote {
  padding: 0 0 0 12px;
  margin: 0 0 0.5em;
  border-left: 4px solid rgb($colorSmoothBlue);
  color: rgb($colorGray80);
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
}

ol,
ul {
  list-style: none;
}

ol,
ul,
li {
  padding: 0;
}

code,
input,
textarea {
  font: inherit;
}

input::placeholder,
textarea::placeholder {
  color: rgb($colorGray60);
}

a img,
fieldset {
  border: 0;
}

b {
  font-weight: normal;
}

i {
  font-style: normal;
}

img {
  max-width: 100%;
}

.clearfix:after {
  content: ' ';
  clear: both;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
}

tsp {
  font-size: 0.3em;
  line-height: 1em;
}

/*
p {
  margin-bottom: 0.5em;
}
 */

p + p {
  margin-top: 0.5em;
}

h1 {
  font-size: 28px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

h4,
h5,
h6 {
  font-size: 100%;
  font-weight: bold;
}

*,
input,
select,
textarea,
option,
button {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: color 0s, border-color 0s;
}

a,
a:hover,
a:visited:hover {
  color: rgb($colorBlue);
  border-color: rgba($colorBlue, 0.3);
}

img {
  display: block;
  max-width: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Стили для меток яндекс карты */
.flat-placemark {
  padding: 4px 8px;
  width: max-content;
  min-width: 64px;
  min-height: 32px;
  font-family: inherit;
  line-height: 24px;
  background-color: rgb($colorWhite);
  color: rgb($colorBlack);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba($colorBlack, 0.09);
}

.flat-placemark-starred {
  @svg-load starred url('components/flat/flat-card/flat-starred-button/starred.svg') {
    fill: rgb($colorBlack);
    stroke: rgb($colorBlack);
  }

  padding-left: 28px;

  background-image: svg-inline(starred);
  background-repeat: no-repeat;
  background-position: 3px center;
  background-size: 26px;
}

.flat-placemark-starred.flat-placemark--hover,
.flat-placemark-starred.flat-placemark--active{
  @svg-load starred url('components/flat/flat-card/flat-starred-button/starred.svg') {
    fill: rgb($colorWhite);
    stroke: rgb($colorWhite);
  }
  background-image: svg-inline(starred);
}

.flat-placemark--hover {
  background-color: rgb($colorLightBlack);
  color: rgb($colorWhite);
}

.flat-placemark--active {
  background-color: rgb($colorLightBlack);
  color: rgb($colorWhite);
}

.flat-placemark__price {
  font-weight: 700;
}

.flat-dot {
  width: 5px;
  height: 5px;
  margin: 1px 0 0 1px;
  border-radius: 50%;
  background-color: rgb($colorBlack);
}
